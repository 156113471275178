import { Table } from 'antd';
import { useEffect, useState } from 'react';
import deribitAPI from './api/api';
import {ResponsiveLine} from '@nivo/line';
import './css/TradingApp.css';


const TradingApp = () => {
  const [instruments, setInstruments] = useState([]);
  const [chartData, setChartData] = useState([]);

  const fetchData = async () => {
    try {
      const instrumentsResponse = await deribitAPI.get('public/get_instruments', {
        params: {
          currency: 'ETH',
          kind: 'future',
          expired: false
        }
      });

      if (instrumentsResponse.status === 200) {
        const expirationTimestamps = await Promise.all(
          instrumentsResponse.data.result.map(async instrument => {
            const instrumentResponse = await deribitAPI.get('/public/get_instrument', {
              params: {
                instrument_name: instrument.instrument_name
              }
            });
            return instrumentResponse.data.result.expiration_timestamp;
          })
        );

        fetchBookSummaries(instrumentsResponse.data.result, expirationTimestamps);
      } else {
        console.log("Error:", instrumentsResponse.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();

    const instrumentsIntervalId = setInterval(fetchData, 1800);

    return () => {
      clearInterval(instrumentsIntervalId);
    };

    // eslint-disable-next-line
  }, []);

  const fetchBookSummaries = async (instruments, expirationTimestamps) => {
    try {
      const responses = await Promise.all(instruments.map(instrument =>
        deribitAPI.get('/public/get_book_summary_by_instrument', {
          params: { instrument_name: instrument.instrument_name }
        })
      ));

      const summaries = responses.map(response =>
        response.status === 200 && response.data.result.length > 0 ? response.data.result[0] : null
      );

      const updatedInstruments = instruments.map((instrument, index) => {
        const bookSummary = summaries[index];
        const instrumentLastPrice = bookSummary.last;
        const currentTime = Date.now();
        const instrumentExpirationTimestamp = instrument.expiration_timestamp;
        const timeDifference = instrumentExpirationTimestamp - currentTime;
        const daysUntilExpiration = Math.round(timeDifference / (1000 * 3600 * 24));
        const ethPerpetualSummary = summaries.find(summary => summary.instrument_name === 'ETH-PERPETUAL');
        const ethPerpetualLastPrice = ethPerpetualSummary.last;
        const premium = parseFloat(instrumentLastPrice / ethPerpetualLastPrice) - 1;
        const annualYield = Math.pow(1 + premium, 365 / daysUntilExpiration) - 1;
        return {
          ...instrument,
          bookSummary,
          premium,
          ethPerpetualLast: ethPerpetualLastPrice,
          annualYield
        };
      });

      setInstruments(updatedInstruments);

      const data = updatedInstruments
        .filter(instrument => new Date(instrument.expiration_timestamp).getFullYear() !== 3000)
        .map(instrument => ({
          x: new Date(instrument.expiration_timestamp),
          premium: instrument.premium.toFixed(4) * 100,
          annualYield: instrument.annualYield.toFixed(4) * 100
        }));

      setChartData(data);

    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const formatNumber = (number) => {
    return number ? number.toLocaleString() : '';
  };

  const columns = [
    {
      title: 'Instrument Name',
      dataIndex: 'instrument_name',
      key: 'instrument_name'
    },
    {
      title: 'Last Price',
      dataIndex: 'bookSummary',
      key: 'last_price',
      render: bookSummary => `$${formatNumber(bookSummary.last)}`
    },
    {
      title: 'Premium',
      dataIndex: 'bookSummary',
      key: 'premium',
      render: (bookSummary, row) => {
        if (bookSummary && row.ethPerpetualLast) {
          const premium = ((bookSummary.last / row.ethPerpetualLast) - 1) * 100;
          const color = premium >= 0 ? 'green' : 'red';
          return <span style={{ color }}>{`${premium.toFixed(2)} %`}</span>;
        }
        return null;
      }
    },
    {
      title: 'Annual Yield',
      dataIndex: 'annualYield',
      key: 'annual_yield',
      render: (annualYield) => {
        if (typeof annualYield === 'number') {
          const color = annualYield >= 0 ? 'green' : 'red';
          return <span style={{ color }}>{`${(annualYield * 100).toFixed(2)} %`}</span>;
        }
        return null;
      }
    },
    {
      title: 'Mark',
      dataIndex: 'bookSummary',
      key: 'mark_price',
      render: bookSummary => `$${formatNumber(bookSummary.mark_price)}`
    },
    {
      title: 'Low Price',
      dataIndex: 'bookSummary',
      key: 'low',
      render: bookSummary => `$${formatNumber(bookSummary.low)}`
    },
    {
      title: 'High Price',
      dataIndex: 'bookSummary',
      key: 'high',
      render: bookSummary => `$${formatNumber(bookSummary.high)}`
    },
    {
      title: '24h Volume',
      dataIndex: 'bookSummary',
      key: 'volume',
      render: bookSummary => `$${formatNumber(bookSummary.volume)}`
    },
    {
      title: 'Change',
      dataIndex: 'bookSummary',
      key: 'price_change',
      render: bookSummary => {
        const change = bookSummary ? bookSummary.price_change : null;
        const color = change && change >= 0 ? 'green' : 'red';
        return <span style={{ color }}>{`${change} %`}</span>;
      }
    },
    {
      title: 'Open Interest',
      dataIndex: 'bookSummary',
      key: 'open_interest',
      render: bookSummary => `$${formatNumber(bookSummary.open_interest)}`
    }
  ];
  return (
    <div className="header">
      <div className="container">
        <div className="table-data-futures">
          <div className='table-data-futures-inner'>
            <h1 className='table-data-futures-title'>Deribit Futures</h1>
            <Table dataSource={instruments} columns={columns} pagination={false} rowKey="instrument_name" />
          </div>
        </div>
        <div className='main-graphics'>
          <div className='main-graphics-inner'>
            <div style={{ width: 590, height: 400, marginTop:8, marginLeft:100 }}>
            <ResponsiveLine
              data={[{
                id: 'Premium',
                data: chartData.map(item => ({ x: item.x, y: item.premium }))
              }, {
                id: 'Annual Yield',
                data: chartData.map(item => ({ x: item.x, y: item.annualYield }))
              }]}
              margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
              xScale={{
                type: 'time',
                format: '%Y-%m-%d',
              }}
              xFormat="time:%Y-%m-%d"
              axisBottom={{
                format: '%b %d',
                legend: 'Date',
                legendOffset: -6,
              }}
              yScale={{
                type: 'linear',
                stacked: false,
                reverse: false,
                min: 'auto', // Set a fixed minimum value
                max: 'auto', // Automatically determine the maximum value
              }}
              axisLeft={{
                legend: 'Value (%)',
                legendOffset: 7,
                legendPosition: 'end',
              }}
              curve="linear"
              enablePoints={true}
              enableGridX={true}
              enableGridY={true}
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: -21,
                  translateY: -1,
                  itemsSpacing: 15,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                }
              ]}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingApp;