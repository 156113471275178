import axios from "axios";

const API_KEY = 'lTiig1W_';
const API_SECRET = 'uvOF0GL_Z0sSAp0P7_F_dvE4e1BcjiPh_Y10_h-momQ';

const deribitAPI = axios.create({
    baseURL: 'https://www.deribit.com/api/v2/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    auth: {
        username: API_KEY,
        password: API_SECRET
      }
});
export default deribitAPI;